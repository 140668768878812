/*

    Name:       oceanic-next
    Author:     Filype Pereira (https://github.com/fpereira1)

    Original oceanic-next color scheme by Dmitri Voronianski (https://github.com/voronianski/oceanic-next-color-scheme)

*/

.cm-s-oceanic-next.CodeMirror { background: #304148; color: #f8f8f2; }
.cm-s-oceanic-next div.CodeMirror-selected { background: rgba(101, 115, 126, 0.33); }
.cm-s-oceanic-next .CodeMirror-line::selection, .cm-s-oceanic-next .CodeMirror-line > span::selection, .cm-s-oceanic-next .CodeMirror-line > span > span::selection { background: rgba(101, 115, 126, 0.33); }
.cm-s-oceanic-next .CodeMirror-line::-moz-selection, .cm-s-oceanic-next .CodeMirror-line > span::-moz-selection, .cm-s-oceanic-next .CodeMirror-line > span > span::-moz-selection { background: rgba(101, 115, 126, 0.33); }
.cm-s-oceanic-next .CodeMirror-gutters { background: #304148; border-right: 10px; }
.cm-s-oceanic-next .CodeMirror-guttermarker { color: white; }
.cm-s-oceanic-next .CodeMirror-guttermarker-subtle { color: #d0d0d0; }
.cm-s-oceanic-next .CodeMirror-linenumber { color: #d0d0d0; }
.cm-s-oceanic-next .CodeMirror-cursor { border-left: 1px solid #f8f8f0; }

.cm-s-oceanic-next span.cm-comment { color: #65737E; }
.cm-s-oceanic-next span.cm-atom { color: #C594C5; }
.cm-s-oceanic-next span.cm-number { color: #F99157; }

.cm-s-oceanic-next span.cm-property { color: #99C794; }
.cm-s-oceanic-next span.cm-attribute,
.cm-s-oceanic-next span.cm-keyword { color: #C594C5; }
.cm-s-oceanic-next span.cm-builtin { color: #66d9ef; }
.cm-s-oceanic-next span.cm-string { color: #99C794; }

.cm-s-oceanic-next span.cm-variable,
.cm-s-oceanic-next span.cm-variable-2,
.cm-s-oceanic-next span.cm-variable-3 { color: #f8f8f2; }
.cm-s-oceanic-next span.cm-def { color: #6699CC; }
.cm-s-oceanic-next span.cm-bracket { color: #5FB3B3; }
.cm-s-oceanic-next span.cm-tag { color: #C594C5; }
.cm-s-oceanic-next span.cm-header { color: #C594C5; }
.cm-s-oceanic-next span.cm-link { color: #C594C5; }
.cm-s-oceanic-next span.cm-error { background: #C594C5; color: #f8f8f0; }

.cm-s-oceanic-next .CodeMirror-activeline-background { background: rgba(101, 115, 126, 0.33); }
.cm-s-oceanic-next .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
