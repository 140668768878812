/*

    Name:       gruvbox-dark
    Author:     kRkk (https://github.com/krkk)

    Original gruvbox color scheme by Pavel Pertsev (https://github.com/morhetz/gruvbox)

*/

.cm-s-gruvbox-dark.CodeMirror, .cm-s-gruvbox-dark .CodeMirror-gutters { background-color: #282828; color: #bdae93; }
.cm-s-gruvbox-dark .CodeMirror-gutters {background: #282828; border-right: 0px;}
.cm-s-gruvbox-dark .CodeMirror-linenumber {color: #7c6f64;}
.cm-s-gruvbox-dark .CodeMirror-cursor { border-left: 1px solid #ebdbb2; }
.cm-s-gruvbox-dark div.CodeMirror-selected { background: #928374; }
.cm-s-gruvbox-dark span.cm-meta { color: #83a598; }

.cm-s-gruvbox-dark span.cm-comment { color: #928374; }
.cm-s-gruvbox-dark span.cm-number, span.cm-atom { color: #d3869b; }
.cm-s-gruvbox-dark span.cm-keyword { color: #f84934; }

.cm-s-gruvbox-dark span.cm-variable { color: #ebdbb2; }
.cm-s-gruvbox-dark span.cm-variable-2 { color: #ebdbb2; }
.cm-s-gruvbox-dark span.cm-variable-3, .cm-s-gruvbox-dark span.cm-type { color: #fabd2f; }
.cm-s-gruvbox-dark span.cm-operator { color: #ebdbb2; }
.cm-s-gruvbox-dark span.cm-callee { color: #ebdbb2; }
.cm-s-gruvbox-dark span.cm-def { color: #ebdbb2; }
.cm-s-gruvbox-dark span.cm-property { color: #ebdbb2; }
.cm-s-gruvbox-dark span.cm-string { color: #b8bb26; }
.cm-s-gruvbox-dark span.cm-string-2 { color: #8ec07c; }
.cm-s-gruvbox-dark span.cm-qualifier { color: #8ec07c; }
.cm-s-gruvbox-dark span.cm-attribute { color: #8ec07c; }

.cm-s-gruvbox-dark .CodeMirror-activeline-background { background: #3c3836; }
.cm-s-gruvbox-dark .CodeMirror-matchingbracket { background: #928374; color:#282828 !important; }

.cm-s-gruvbox-dark span.cm-builtin { color: #fe8019; }
.cm-s-gruvbox-dark span.cm-tag { color: #fe8019; }
